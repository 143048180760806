import * as React from "react"
import "../components/team-row"
import TeamRow from "../components/team-row";
import Img from "gatsby-image"
import { StaticImage } from "gatsby-plugin-image"
import Carousel from 'react-grid-carousel'
import YoutubeVids from "../components/youtube-vids";
import BlerdCover from "../images/blerdimension-cover.png";
import SEO from "../components/seo";
import "../../node_modules/@fortawesome/fontawesome-free/css/all.css";

// markup
const IndexPage = () => {
  return (
      <>
          <SEO
              description="Welcome to the blerdimension"
              title="Blerdimension"
              bannerImg={BlerdCover}
          />
          <header className="masthead" style={{backgroundImage: `url(${BlerdCover})`}}>
              <div  className="container">
                  {/*<div className="masthead-subheading">Welcome To the blerdimension!</div>*/}
                  {/*<div className="masthead-heading text-uppercase">It's Nice To Meet You</div>*/}
                  {/*<a className="btn btn-primary btn-xl text-uppercase" href="#services">Tell Me More</a>*/}
              </div>
          </header>
        <main className="container">
            <section className="page-section bg-light" id="content">
            {/*  */}
              <YoutubeVids/>
            </section>
          <section id="podcast-episodes" className="row">
            {/*  */}
          </section>
          {/*<section className="page-section bg-light" id="team">*/}
          {/*    /!**!/*/}
          {/*    <TeamRow/>*/}
          {/*</section>*/}
            <section className="page-section" id="social-row">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Subscribe and Follow</h2>
                        <h3 className="section-subheading text-muted">Join the Blerdimension Community</h3>
                    </div>
                    <div className="row text-center justify-content-center">
                        <div className="col-sm-6 col-md-2">
                            <a href="https://anchor.fm/blerdimension" className="btn btn-dark btn-social"><i className="fas fa-microphone"></i></a>
                            <h4 className="my-3">Podcast</h4>
                        </div>
                        <div className="col-sm-6 col-md-2">
                            <a href="https://www.youtube.com/channel/UCsSy_UKX7zffw6oB2JoOB4w" className="btn btn-dark btn-social"><i className="fab fa-youtube"></i></a>
                                <h4 className="my-3">Youtube</h4>
                        </div>
                        <div className="col-sm-6 col-md-2">
                            <a href="https://www.twitch.tv/blerdimension" className="btn btn-dark btn-social"><i className="fab fa-twitch"></i></a>
                                <h4 className="my-3">Twitch</h4>
                                {/*<p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima*/}
                                {/*    maxime quam architecto quo inventore harum ex magni, dicta impedit.</p>*/}
                        </div>
                        <div className="col-sm-6 col-md-2">
                            <a href="https://www.instagram.com/blerdimension" className="btn btn-dark btn-social"><i className="fab fa-instagram"></i></a>
                                <h4 className="my-3">Instagram</h4>
                        </div>
                        <div className="col-sm-6 col-md-2">
                            <a href="https://discord.gg/gc57q7V" className="btn btn-dark btn-social"><i className="fab fa-discord"></i></a>
                            <h4 className="my-3">Discord</h4>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <footer className="container-fluid">
          <section className="row"></section>
        </footer>
      </>
  )
}

export default IndexPage
