import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Carousel from 'react-grid-carousel'

const YoutubeVids = () => {

    const YoutubeVids = useStaticQuery(graphql`
    {
      allYoutubeVideo {
        edges {
          node {
            title
            videoId
            description
          }
        }
      }
    }
  `)

    const {allYoutubeVideo: {edges: videos}} = YoutubeVids;

    console.log(videos);

    return (

        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase">Our Latest Content</h2>
                <h3 className="section-subheading text-muted">Make sure you subscribe to our channel on <a href="https://www.youtube.com/channel/UCsSy_UKX7zffw6oB2JoOB4w">Youtube</a></h3>
            </div>
            <div className="row">
                { videos.map( video => {
                    return(
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="content-item">
                            <div className="video-wrapper">
                                <iframe
                                    src={"https://www.youtube.com/embed/" + video.node.videoId}
                                    width="560" height="349"
                                    title={video.node.title}
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    frameBorder="0"
                                    webkitallowfullscreen="true"
                                    mozallowfullscreen="true"
                                    allowFullScreen
                                />
                            </div>
                        </div>
                    </div>
                    )
                })}
            </div>
            <div className="col-lg-8 mx-auto text-center">
                {/*<p className="large text-muted">Lorem ipsum dolor sit amet,*/}
                {/*    consectetur adipisicing elit. Aut eaque, laboriosam veritatis, quos non quis ad perspiciatis, totam*/}
                {/*    corporis ea, alias ut unde.</p>*/}
                <a className="btn btn-primary btn-xl text-uppercase" href="https://www.youtube.com/channel/UCsSy_UKX7zffw6oB2JoOB4w">View More</a>
            </div>
        </div>
    );
}

export default YoutubeVids


